<template>
    <div class="main_box">
        <div class="record_title">
            <img class="record_back" src="img/backw.png" alt="" @click="goBack">
            <span>关系榜</span>
        </div>
        <div class="btn_box">
            <div :style="curIndex == index ? ('background-color:' + item.bc + ';' + 'color:' + color) : ''"
                v-for="(item, index) in tabs" :key="index" @click="clickTab(index)">{{ item.name }}</div>
        </div>
        <div class="net_box_" :style="'background-image: url(' + bg + ')'">
            <div class="item_box_">
                <div class="noData margin-top-xl" v-if="list.length == 0">
                    <img src="img/noData.png" alt="">
                    <p>暂无数据</p>
                </div>
                <van-list v-else v-model="loading" :finished="finished" finished-text="没有更多了" @load="getNet" offset="5">
                    <div class="top_box_" v-for="(item, index) in list" :key="index">
                        <div style="display: flex;align-items: center;flex-direction: column;">
                            <img class="face_" :src="item.a_head_portrait" alt="">
                            <div class="nickname_">{{ item.a_nickname }}</div>
                        </div>
                        <div class="rank_bg_box">
                            <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank + '.png'"
                                alt="">
                        </div>
                        <div style="display: flex;align-items: center;flex-direction: column;">
                            <img class="face_" :src="item.b_head_portrait" alt="">
                            <div class="nickname_">{{ item.b_nickname }}</div>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>

    </div>
</template>
<script>
import "@/assets/css/relationNet.css"
import { myNetRank } from "@/api/relationNet"
export default {
    data() {
        return {
            tabs: [{ name: '玩伴', index: 0, bc: '#70e2a9' }, { name: '兄弟', index: 1, bc: '#6cc4e6' }, { name: '闺蜜', index: 2, bc: '#debbaf' }, { name: '恋人', index: 3, bc: '#cb9afe' }],
            color: '#111111',
            curIndex: 0,
            loading: false,
            finished: false,
            list: [],
            item: {},
            showChildren: false,
            page: 1,
            bg: 'img/relationNet/net_bg_3__.png'
        }
    },
    created() {
        this.getNet()
    },
    methods: {
        clickTab(i) {
            this.curIndex = i
            if (i == 0) {
                this.bg = 'img/relationNet/net_bg_3__.png'
            } else if (i == 1) {
                this.bg = 'img/relationNet/net_bg_1__.png'
            } else if (i == 2) {
                this.bg = 'img/relationNet/net_bg_2__.png'
            } else {
                this.bg = 'img/relationNet/net_bg_0__.png'
            }
            console.log(this.bg)
            this.page = 1
            this.finished = false
            this.loading = true
            this.list = []
            this.getNet()
        },
        getNet() {
            myNetRank({ type: this.curIndex + 1, page: this.page, rows: 15 }).then(res => {
                this.loading = false
                this.list = this.list.concat(res.data);
                this.page = this.page + 1
                if (res.data.length < 10) {
                    this.finished = true;
                }
            }, error => {
                this.loading = false;
                this.finished = true;
            }).catch(error => {
                this.loading = false;
                this.finished = true;
            })
        },
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>